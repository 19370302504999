<template>
  <div class="vh100 pb-4" style="background-color: #153E88; overflow-y: scroll;">
    <Header> </Header>
    <div class="card-body">
      <div class="container pt-3">
        <div class="text-start pb-2">

          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;โครงการเงินอุดหนุนเพื่อเลี้ยงดูเด็กแรกเกิด เป็น
          ความตั้งใจของรัฐบาลที่จะช่วยแบ่งเบาค่าใช้จ่ายในการ
          เลี้ยงดูเด็กของครอบครัวยากจน โดยจะให้เงินอุดหนุนแก่
          แม่ของเด็ก ๖๐๐ บาท/คน/เดือน เป็นเวลา ๖ ปี <br>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;พ่อแม่สามารถใช้เงินอุดหนุนนี้ ให้เป็นประโยชน์ต่อ
          ลูกน้อย เช่น ค่าเดินทางไปรับบริการสาธารณสุข ค่าอาหาร
          ที่มีประโยชน์สำหรับแม่ในช่วงเลี้ยงลูกด้วยนมแม่ ฯลฯ ซึ่ง
          จะส่งผลให้เด็กทุกคนเติบโตอย่างมีคุณภาพ

        </div>
        <div class="line pb-2"></div>
        <div class="text-start mt-2">
          คุณสมบัติเด็กแรกเกิดที่มีสิทธิ์<br>
        </div>
        <div class="text-start ms-2">
          &nbsp;&nbsp;&nbsp;&nbsp;๑.มีสัญชาติไทย (พ่อ แม่ หรือฝ่ายใดฝ่ายหนึ่งมี
          สัญชาติไทย)
          <br>
          &nbsp;&nbsp;&nbsp;&nbsp;๒.เกิดตั้งแต่วันที่ ๑ ตุลาคม ๒๕๕๘ เป็นต้นไป จน
          มีอายุครบ ๖ ปี
          <br>
          &nbsp;&nbsp;&nbsp;&nbsp;๓.ไม่อยู่ในสถานสงเคราะห์ของหน่วยงานของรัฐ
          หรือเอกชน<br>
        </div>
        <div class="text-start mt-2">
          คุณสมบัติผู้ปกครองที่มีสิทธิลงทะเบียน<br>
        </div>
        <div class="text-start ms-2">
          &nbsp;&nbsp;&nbsp;&nbsp;๑. มีสัญชาติไทย
          <br>
          &nbsp;&nbsp;&nbsp;&nbsp;๒. เป็นบุคคลที่รับเด็กแรกเกิดไว้ในความอุปการะ
          <br>
          &nbsp;&nbsp;&nbsp;&nbsp;๓. เด็กแรกเกิดต้องอาศัยรวมอยู่ด้วย<br>
          &nbsp;&nbsp;&nbsp;&nbsp; ๔. อยู่ในครัวเรือนที่มีรายได้น้อย คือ สมาชิกครัว
          เรือนมีรายได้เฉลี่ยไม่เกิน ๑๐๐,๐๐๐ บาท/คน/ปี<br>
        </div>
        <div class="text-start mt-2" style="font-weight: 300;">
          กรณี: แม่เด็กเป็นต่างด้าว หรือไม่มีสถานะทางทะเบียนราษฎร์
          หรือเสียชีวิต อนุโลมให้พ่อเด็กที่มีสัญชาติไทยตามสูติบัตร
          ลงทะเบียนแทนแม่เด็กได้<br>
        </div>

      </div>

      <div class="container position-relative mt-3" style="background-color: #FFF975; width: 100%;">

        <div class="text-center pt-3">
          การรับรองสถานะครัวเรือน (ดร.๐๒)
        </div>

        <div class="text-start ms-2 mt-2">
          &nbsp;&nbsp;&nbsp;&nbsp;ผู้รับรองคนที่ ๑ : อาสาสมัครพัฒนาสังคมและความมั่น
          คงของมนุษย์ (อพม.) หรืออาสาสมัครสาธารณสุขประจำ
          หมู่บ้าน (อสม.)
          <br>
          &nbsp;&nbsp;&nbsp;&nbsp;ผู้รับรองคนที่ ๒ : กำนัน ผู้ใหญ่บ้าน ผู้ช่วยผู้ใหญ่บ้าน
          หรือปลัดเทศบาลหรือผู้ที่ปลัดมอบหมาย
          <br>


          &nbsp;&nbsp;&nbsp;&nbsp;"เด็กแรกเกิด" หมายถึง<br>
          เด็กที่มีสัญชาติไทยและเกิดตั้งแต่วันที่ 1 ตุลาคม ๒๕๕๘
          เป็นต้นไปจนอายุครบ ๖ ปี ซึ่งอาจเป็นผู้ได้รับเงินสงเคราะห์
          เป็นครั้งคราว หรือเบี้ยความพิการหรือเงินสงเคราะห์บุตร
          จากกองทุนประกันสังคม ทั้งนี้ ต้องไม่เป็นผู้ได้รับเงินช่วย
          เหลือจากรัฐวิสาหกิจ หรืออยู่ในความอุปการะของหน่วย
          งานรัฐ หรือเอกชน<br>

          &nbsp;&nbsp;&nbsp;&nbsp;"ผู้ปกครอง" หมายถึง<br>
          บิดา มราดา หรือบุคคลอื่น ซึ่งรับเด็กแรกเกิดไว้ในความ
          อุปการะเลี้ยงดูบุตร โดยเด็กแรกเกิดพักอาศัยรวมอยู่ด้วย
          <br>


          &nbsp;&nbsp;&nbsp;&nbsp;"ครัวเรือนมีรายได้น้อย" หมายถึง<br>
          ครัวเรือนเด็กแรกเกิดที่มีรายได้รวมเฉลี่ยไม่เกิน ๑๐๐,๐๐๐
          บาท/คน/ปี ทั้งนี้ ให้นับรวมรายได้ในรอบปีของสมาชิกทุก
          คนที่อาศัยอยู่ในครัวเรือนแห่งนั้น ติดต่อกันมาไม่น้อยกว่า
          ๑๘๐ วัน ในรอบปีที่ผ่านมา
          <br>
          <br>
        </div>



      </div>

      <div class="container pt-1">


        <div class="d-flex justify-content-center align-items-center mt-4"
          style="border-radius: 30px; color: white; background-color: #153E88; width: 100%; height: 50px; text-align: center; font-size: 16px;">
          เอกสารประกอบการลงทะเบียน
        </div>

        <div class="text-start ms-2 mt-3">

          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;๑. แบบคำร้องของลงทะเบียน (ดร.๐๑)<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;๒. แบบรับรองสถานะของครัวเรือน (ดร.๐๒)<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;๓. บัตรประจำตัวประชาชนของผู้ปกครอง<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;๔. สูติบัตรเด็กแรกเกิด<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;๕. สมุดบัญชีเงินฝากธนาคารของผู้ปกครองธนาคาร
          ใดก็ได้ แต่ต้องผูกพร้อมเพย์ด้วยเลขประจำตัวประชาชน<br>

        </div>

        <div class="line mt-3 mb-3"></div>

        <div class="container pb-5">
          <div class="text-start ms-2 pt-3">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;เด็กแรกเกิด ที่เกิดตั้งแต่ ๑ ตุลาคม ๒๕๕๘
            เป็นต้นไป ได้รับเงินเดือนละ ๖๐๐ บาท นับตั้งแต่
            เดือนที่ยื่นขอรับสิทธิ จนอายุครบ ๖ ปี
          </div>
        </div>




      </div>

    </div>







    <Fotter></Fotter>





  </div>
</template>

<script>
import Header from "@/components/Header";
import Fotter from "@/components/Fotter";
import { defineComponent, onMounted, inject } from "vue";
import { SET_REGISTER } from "@/store/modules/RegisterModel";
import liff from "@line/liff";
export default defineComponent({
  name: "Older",
  components: {
    Header,
    Fotter
  },
  setup() {
    const store = inject("store");
    const router = inject("router");

    const Profire = store.getters.getProfire;
    const ProfireLine = store.getters.getProfireLine;
    const AppName = process.env.VUE_APP_NAME;

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const close = () => {
      liff.ready.then(() => {
        liff.closeWindow();
      });
    };

    const edit = () => {
      store.dispatch(SET_REGISTER, Profire);
      router.push({ name: "Edit" });
    };

    return {
      edit,
      close,
      Profire,
      ProfireLine,
      AppName,
      Header
    };
  },
});
</script>

<style scoped>
.line {
  margin-top: 5px;
  margin-bottom: 5px;
  border-top: 2px solid yellow;
}

.mini-h {
  font-size: 20px;
  margin-left: 5px;
}

.icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  color: white;
}

.header-logo-text {
  color: white;
  position: absolute;
  top: 42px;
  width: 100%;
}

.img-profile {
  width: 80px;
  border-radius: 50%;
  border: 2px solid rgb(255, 255, 255);
  background-color: white;
}

.title-home {
  font-size: 20px;
  font-weight: bold;
  background-color: #0196c280;
  color: whitesmoke;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.mn-profile {
  height: 50px;
  min-height: 50px;
  border-bottom: 2px solid var(--color);
  padding: 10px;
  background-color: white;
}

.card-header-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3EB6EB;
  text-align: center;
  height: 60px;
  min-height: 60px;
  position: absolute;
  bottom: 0;
  margin: 0;
  width: 100%;
  border-radius: 0px 0px 20px 20px;
}

.card-header-bottom h5 {
  font-size: 24px;
  color: white;
}

.card-header-bottom span {
  font-size: 22px;
  color: #FFF975 !important;
}

.bt-main {
  width: 100%;
  min-height: 60px;
  background-color: #0D3F94;
  color: white;
  border-radius: 10px;
  font-size: 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease-out;
}

.bt-main:hover {
  background-color: #0D3F94;
}

.bt-main:active {
  background-color: #1751bb;
  transition: background-color 0s;
  opacity: 0.5;
}

.card-body {
  padding: 0;
  margin-top: 0;
  margin-left: 16px;
  margin-right: 16px;
  background-color: white;
  color: #0D3F94;
}



.card-box {
  padding: 16px;
  margin-left: 16px;
  margin-right: 16px;
  background-color: transparent;
  color: #FFF975;
  border-radius: 15px;
  border: 2px solid rgb(252, 252, 252);
}
</style>
