import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import Register from "@/views/register/Register.vue";
import Register_1 from "@/views/register/Register_1.vue";
import Register_2 from "@/views/register/Register_2.vue";
import Profile from "@/views/profile/Profile.vue";
import Edit from "@/views/edit/Edit.vue";
import Edit_1 from "@/views/edit/Edit_1.vue";
import Dashboard from "@/views/Dashboard.vue";
import Older from "@/views/benefit/Older.vue";

import Aids from "@/views/benefit/Aids.vue";
import Child from "@/views/benefit/Child.vue";
import Abnormal from "@/views/benefit/Abnormal.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/register/step1",
    name: "Register_1",
    component: Register_1,
  },
  {
    path: "/register/step2",
    name: "Register_2",
    component: Register_2,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/edit",
    name: "Edit",
    component: Edit,
  },
  {
    path: "/edit/step1",
    name: "Edit_1",
    component: Edit_1,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },

  {
    path: "/older",
    name: "Older",
    component: Older,
  },

  {
    path: "/child",
    name: "Child",
    component: Child,
  },

  {
    path: "/aids",
    name: "Aids",
    component: Aids,
  },
  {
    path: "/abnormal",
    name: "Abnormal",
    component: Abnormal,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
