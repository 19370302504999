<template>
  <div class="vh100 pb-4" style="background-color: #153E88; overflow-y: scroll;">
    <Header> </Header>
    <div class="card-body">
      <div class="container pt-3">
        <div class="text-start">
          สิทธิประโยชน์ผู้ป่วยเอดส์<br>
          &nbsp; &nbsp; &nbsp; &nbsp; ระเบียบกระทรวงมหาดไทยว่าด้วยการจ่ายเงิน
          สงเคราะห์เพื่อการยังชีพขององค์กรปกครองส่วนท้องถิ่น
        </div>


        <div class="d-flex justify-content-center align-items-center mt-4"
          style="border-radius: 30px; color: white; background-color: #153E88; width: 100%; height: 50px; text-align: center; font-size: 16px;">

          ขั้นตอนการยื่นคำขอ
        </div>

        <div class="text-start ms-2 mt-2 ">
          &nbsp;&nbsp;&nbsp;&nbsp; ผู้ป่วยเอดส์สามารถยื่นคำขอรับเงินสงเคราะห์ได้
          ตลอด โดยนำเอกสารมายื่น ณ กองสวัสดิการสังคม เทศ-
          บาลตำบลเทพราช ดังนี้
          <br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;๑. สำเนาบัตรประชาชน<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;๒. สำเนาทะเบียนบ้าน<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;๓. ใบรับรองแพทย์
          <br>
        </div>

        <div class="line mt-3"></div>

        <div class="container pb-5">
          <div class="text-start ms-2 pt-3">
            เบี้ยยังชีพผู้ป่วยเอดส์ที่ได้รับ <br>

          </div>
          <div class="text-center">
            ได้รับ เดือนละ ๕๐๐ บาท <br>
          </div>


        </div>
      </div>


    </div>

    <Fotter></Fotter>





  </div>
</template>

<script>
import Header from "@/components/Header";
import Fotter from "@/components/Fotter";
import { defineComponent, onMounted, inject } from "vue";
import { SET_REGISTER } from "@/store/modules/RegisterModel";
import liff from "@line/liff";
export default defineComponent({
  name: "Aids",
  components: {
    Header,
    Fotter
  },
  setup() {
    const store = inject("store");
    const router = inject("router");

    const Profire = store.getters.getProfire;
    const ProfireLine = store.getters.getProfireLine;
    const AppName = process.env.VUE_APP_NAME;

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const close = () => {
      liff.ready.then(() => {
        liff.closeWindow();
      });
    };

    const edit = () => {
      store.dispatch(SET_REGISTER, Profire);
      router.push({ name: "Edit" });
    };

    return {
      edit,
      close,
      Profire,
      ProfireLine,
      AppName,
      Header
    };
  },
});
</script>

<style scoped>
.line {
  margin-top: 5px;
  margin-bottom: 5px;
  border-top: 2px solid yellow;
}

.mini-h {
  font-size: 20px;
  margin-left: 5px;
}

.icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  color: white;
}

.header-logo-text {
  color: white;
  position: absolute;
  top: 42px;
  width: 100%;
}

.img-profile {
  width: 80px;
  border-radius: 50%;
  border: 2px solid rgb(255, 255, 255);
  background-color: white;
}

.title-home {
  font-size: 20px;
  font-weight: bold;
  background-color: #0196c280;
  color: whitesmoke;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.mn-profile {
  height: 50px;
  min-height: 50px;
  border-bottom: 2px solid var(--color);
  padding: 10px;
  background-color: white;
}

.card-header-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3EB6EB;
  text-align: center;
  height: 60px;
  min-height: 60px;
  position: absolute;
  bottom: 0;
  margin: 0;
  width: 100%;
  border-radius: 0px 0px 20px 20px;
}

.card-header-bottom h5 {
  font-size: 24px;
  color: white;
}

.card-header-bottom span {
  font-size: 22px;
  color: #FFF975 !important;
}

.bt-main {
  width: 100%;
  min-height: 60px;
  background-color: #0D3F94;
  color: white;
  border-radius: 10px;
  font-size: 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease-out;
}

.bt-main:hover {
  background-color: #0D3F94;
}

.bt-main:active {
  background-color: #1751bb;
  transition: background-color 0s;
  opacity: 0.5;
}

.card-body {
  padding: 0;
  margin-top: 0;
  margin-left: 16px;
  margin-right: 16px;
  background-color: white;
  color: #0D3F94;
}



.card-box {
  padding: 16px;
  margin-left: 16px;
  margin-right: 16px;
  background-color: transparent;
  color: #FFF975;
  border-radius: 15px;
  border: 2px solid rgb(252, 252, 252);
}
</style>
