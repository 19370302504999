<template>
  <div class="vh100 pb-4" style="background-color: #153E88; overflow-y: scroll;">
    <Header> </Header>
    <div class="card-body">
      <div class="container pt-3">
        <div class="text-start">
          สิทธิของผู้สูงอายุ<br>
          &nbsp; &nbsp; &nbsp; &nbsp; พระราชบัญญัติผู้สูงอายุ พ.ศ.๒๕๔๖ แก้ไชเพิ่มเติม
          ฉบับที่ ๒ พ.ศ.๒๕๕๓ เป็นกฎหมายที่ให้การคุ้มครอง ส่งเสริม
          และสนับสนุนผู้สูงอายุได้รับสิทธิในด้านต่างๆ รวมทั้งก่อให้เกิด
          สิทธิประโยชน์ต่อองค์กรด้านผู้สูงอายุและประชาชนทั่วไป เช่น
          เงินสงเคราะห์ผู้สูงอายุ
        </div>
        <div class="line"></div>
        <div class="text-start mt-2">
          คุณสมบัติของผู้มีสิทธิได้รับเบี้ยยังชีพ<br>
        </div>
        <div class="text-start ms-2">
          &nbsp;&nbsp;&nbsp;&nbsp;๑. มีสัญชาติไทย <br>
          &nbsp;&nbsp;&nbsp;&nbsp;๒. มีภูมิลำเนาอยู่ในเขตเทศบาลตำบลเทพราชตาม
          ทะเบียนบ้าน
          <br>
          &nbsp;&nbsp;&nbsp;&nbsp;๓. มีอายุหกสิบปีขึ้นไป ซึ่งได้ลงทะเบียน และยื่นคำ
          ขอรับเบี้ยยังชีพผู้สูงอายุต่อเทศบาลตำบลเทพราช<br>
          &nbsp;&nbsp;&nbsp;&nbsp;๔. ไม่เป็นผู้ได้รับสวัสดิการหรือสิทธิประโยชน์อื่นใด
          จากหน่วยงานของรัฐ รัฐวิสาหกิจ หรือองค์กรปกครอง
          ส่วนท้องถิ่น ได้แก่ ผู้รับเงินบำนาญ เบี้ยหวัด บำนาญ
          พิเศษ หรือเงินอื่นใดในลักษณะเดียวกัน ผู้สูงอายุ ที่อยู่
          ในสถานสงเคราะห์ของรัฐหรือองค์กรปกครองส่วน
          ท้องถิ่น ผู้ได้รับเงินเดือน ค่าตอบแทน รายได้ประจำ
          หรือผลประโยชน์ตอบแทนอย่างอื่นที่รัฐหรือองค์กร
          ปกครอบส่วนท้องถิ่นจัดให้เป็นประจำ ยกเว้นผู้พิการ
          และผู้ป่วยเอดส์ตามระเบียบกระทรวงมหาดไทยว่า
          ด้วยการจ่ายเงินสงเคราะห์เพื่อการยังชีพขององค์กร
          ปกครองส่วนท้องถิ่น พ.ศ.๒๕๔๘<br>
        </div>

        <div class="d-flex justify-content-center align-items-center mt-4"
          style="border-radius: 30px; color: white; background-color: #153E88; width: 100%; height: 50px; text-align: center; font-size: 16px;">

          ขั้นตอนการยื่นคำขอ
        </div>

        <div class="text-start ms-2 mt-2">
          &nbsp;&nbsp;&nbsp;&nbsp; ภายในเดือนพฤศจิกายนของทุกปีให้ผู้ที่จะมีอายุครบ
          หกสิบปีบริบูรณ์ขึ้นไปในปีงบประมาณถัดไป ลงทะเบียนและ
          ยื่นคำขอรับเงินเบี้ยยังชีพผู้สูงอายุด้วยตนเอง ณ เทศบาล
          ตำบลเทพราช หรือสถานที่ที่เทศบาลตำบลเทพราชกำหนด
          โดยมีหลักฐาน ดังนี้<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;๑. บัตรประจำตัวประชาชน<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;๒. ทะเบียนบ้านพร้อมสำเนา<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;๓. สมุดบัญชีเงินฝากธนาคารพร้อมสำเนา ในกรณีที่
          มีความจำเป็นผู้สูงอายุไม่สามารถลงทะเบียนด้วยตนเองได้
          อาจมอบอำนาจเป็นลายลักษณ์อักษรให้ผู้อื่นเป็นผู้ยื่นคำขอ
          รับเบี้ยยังชีพผู้สูงอายุแทนได้
          <br>
        </div>
      </div>


      <div class="container position-relative mt-3" style="background-color: #FFF975; width: 100%;">

        <div class="text-start ms-2 p-3">
          สิทธิประโยชน์อื่นสำหรับผู้สูงอายุ <br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;๑. การให้คำปรึกษาด้านสิทธิประโยชน์ที่พึงจะได้รับ<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;๒. การประกอบอาชีพหรือการฝึกอาชีพที่เหมาะสม<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;๓. การส่งเสริมความรู้ภูมิปัญญาท้องถิ่น<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;๔. การพัฒนาตนเองและการมีส่วนร่วมในกิจกรรมทางสังคม<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;๕. การสงเคราะห์เบี้ยยังชีพและการอื่นตามที่คณะกรรมการประกาศกำหนด<br>
        </div>


      </div>


      <div class="container pb-4">
        <div class="text-start ms-2 pt-3">
          เบี้ยยังชีพผู้สูงอายุที่ได้รับ <br>

        </div>
        <div class="text-center">
          อายุ ๖๐-๖๙ ปี ได้รับ ๖๐๐ บาท <br>
          อายุ ๗๐-๗๙ ปี ได้รับ ๗๐๐ บาท <br>
          อายุ ๘๐-๘๙ ปี ได้รับ ๘๐๐ บาท <br>
          อายุ ๙๐ ปี ขึ้นไป ได้รับ ๑,๐๐๐ บาท <br>

        </div>


      </div>



    </div>

    <div class="card-box mt-3">
      <div class="text-start ms-2 pt-2">
        การสิ้นสุดการได้รับเบี้ยยังชีพผู้สูงอายุ <br>

      </div>
      <div class="text-start ms-2">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;๑. ตาย<br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;๒. ขาดคุณสมบัติตามระเบียบฯ<br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;๓. แจ้งสละสิทธิการขอรับเงินเบี้ยยังชีพผู้สูงอายุ เป็น
        หนังสือต่อเทศบาลตำบลเทพราช<br>

      </div>
    </div>

    <div class="container mb-4">
      <div class="container position-relative mt-3 text-center"
        style="background-color: #FFF975; width: 100%; padding: 16px; color: #153E88; font-size: 16px;">

        สิทธิประโยชน์ค่าจัดการศพผู้สูงอายุ
      </div>

      <div class="text-start ms-2 mt-2" style="color: white;">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ๑. ผู้ยื่นคำขอรับเงินสงเคราะห์ค่าจัดการศพผู้สูงอายุ
        หมายถึง ผู้ที่รับผิดชอบในการจัดการศพผู้สูงอายุตามประ-
        เพณี ซึ่งได้แก่ บิดา มารดา สามี ภรรยา บุตร ญาติพี่น้อง
        ของผู้เสียชีวิต
        <br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ๒. ผู้ให้คำรับรองผู้รับผิดชอบในการจัดการศพผู้สูง
        อายุตามประเพณี หมายถึง ผู้ใหญ่บ้าน กำนัน นายกองค์การ
        บริหารส่วนตำบล/นายกเทศมนตรี
        <br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;๓. คุณสมบัติผู้สูงอายุ (เสียชีวิต)
        - มีอายุ ๖๐ ปี ขึ้นไป
        - มีสัญชาติไทย
        - ผู้สูงอายุที่มีบัตรสวัสดิการแห่งรัฐ เว้นแต่ผู้สูง
        อายุที่มีคุณสมบัติตามเกญฑ์บัตรสวัสดิการแห่งรัฐ
        แต่ยังไม่มีบัตรสวัสดิการแห่งรัฐหรือยังไม่ได้ลงทะ-
        เบียน ให้ผู้ใหญ่บ้าน กำนัน นายกองค์การบริหารส่วน
        ตำบล/นายกเทศมนตรี
        <br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;๔. หลักฐานการยื่นคำขอ <br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - ใบมรณบัตรของผู้เสียชีวิต <br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - บัตรสวัสดิการแห่งรัฐของผู้เสียชีวิต <br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - สำเนาบัตรประชาชน, สำเนาทะเบียนบ้านของผู้เสียชีวิต และผู้ยื่น<br>
        <!-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - สำเนาบัตรประชาชน,สำเนาทะเบียนบ้าน <br> -->
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - สำเนาบัญชีธนาคาร ผู้รับรอง<br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - สำเนาบัตรข้าราชการ

        <br>

      </div>
    </div>

    <Fotter></Fotter>





  </div>
</template>

<script>
import Header from "@/components/Header";
import Fotter from "@/components/Fotter";
import { defineComponent, onMounted, inject } from "vue";
import { SET_REGISTER } from "@/store/modules/RegisterModel";
import liff from "@line/liff";
export default defineComponent({
  name: "Older",
  components: {
    Header,
    Fotter
  },
  setup() {
    const store = inject("store");
    const router = inject("router");

    const Profire = store.getters.getProfire;
    const ProfireLine = store.getters.getProfireLine;
    const AppName = process.env.VUE_APP_NAME;

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const close = () => {
      liff.ready.then(() => {
        liff.closeWindow();
      });
    };

    const edit = () => {
      store.dispatch(SET_REGISTER, Profire);
      router.push({ name: "Edit" });
    };

    return {
      edit,
      close,
      Profire,
      ProfireLine,
      AppName,
      Header
    };
  },
});
</script>

<style scoped>
.line {
  margin-top: 5px;
  margin-bottom: 5px;
  border-top: 2px solid yellow;
}

.mini-h {
  font-size: 20px;
  margin-left: 5px;
}

.icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  color: white;
}

.header-logo-text {
  color: white;
  position: absolute;
  top: 42px;
  width: 100%;
}

.img-profile {
  width: 80px;
  border-radius: 50%;
  border: 2px solid rgb(255, 255, 255);
  background-color: white;
}

.title-home {
  font-size: 20px;
  font-weight: bold;
  background-color: #0196c280;
  color: whitesmoke;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.mn-profile {
  height: 50px;
  min-height: 50px;
  border-bottom: 2px solid var(--color);
  padding: 10px;
  background-color: white;
}

.card-header-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3EB6EB;
  text-align: center;
  height: 60px;
  min-height: 60px;
  position: absolute;
  bottom: 0;
  margin: 0;
  width: 100%;
  border-radius: 0px 0px 20px 20px;
}

.card-header-bottom h5 {
  font-size: 24px;
  color: white;
}

.card-header-bottom span {
  font-size: 22px;
  color: #FFF975 !important;
}

.bt-main {
  width: 100%;
  min-height: 60px;
  background-color: #0D3F94;
  color: white;
  border-radius: 10px;
  font-size: 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease-out;
}

.bt-main:hover {
  background-color: #0D3F94;
}

.bt-main:active {
  background-color: #1751bb;
  transition: background-color 0s;
  opacity: 0.5;
}

.card-body {
  padding: 0;
  margin-top: 0;
  margin-left: 16px;
  margin-right: 16px;
  background-color: white;
  -color: #0D3F94;
}



.card-box {
  padding: 16px;
  margin-left: 16px;
  margin-right: 16px;
  background-color: transparent;
  color: #FFF975;
  border-radius: 15px;
  border: 2px solid rgb(252, 252, 252);
}
</style>
