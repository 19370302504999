<template>
  <div class="row">
    <div class="col-12">
      <div class="card concard card-shadow">

        <div class="card-header position-relative">
          <!-- <img src="@/assets/bg3.png" class="image-bg" alt="image" /> -->


          <div style="
        width: 200px;
        height: 60px;
        position: absolute;
        left: 50%;
        top: 25%;
        transform: translate(-50%, -50%);
        text-align: center;
      ">
            <img src="@/assets/logo.png" style="width: 65%; border-radius: 50%" alt="..." />
          </div>

          <div class="card-header-bottom">
            <h5>ตรวจสอบ<span>สิทธิประโยชน์</span></h5>
          </div>





        </div>





        <div class="card-body mt-2">


          <button class="bt-main mb-3" @click="gopage('child')">
            <i class="bi bi-circle-fill ms-5 position-absolute"
              style="left: 0; color: #FFF975; font-size: 1.5rem;"></i>สิทธิประโยชน์เด็กแรกเกิด
          </button>
          <button class="bt-main mb-3" @click="gopage('older')">
            <i class="bi bi-hexagon-fill ms-5 position-absolute"
              style="left: 0; color: #FFF975;  transform: rotate(90deg); font-size: 1.6rem;"></i>สิทธิประโยชน์ผู้สูงอายุ
          </button>
          <button class="bt-main mb-3" @click="gopage('abnormal')">
            <i class="bi bi-star-fill ms-5 position-absolute" style="left: 0; color: #FFF975; font-size: 1.6rem;"></i>
            สิทธิประโยชน์ผู้พิการ
          </button>
          <button class="bt-main mb-3" @click="gopage('aids')">
            <i class="bi bi-square-fill ms-5 position-absolute"
              style="left: 0; color: #FFF975; transform: rotate(135deg); font-size: 1.3rem;"></i>สิทธิประโยชน์ผู้ป่่วยเอดส์
          </button>




        </div>

        <!-- <div class="card-footer white">
          <div class="d-flex justify-center align-center">
            <button @click="close" class="button">ปิดหน้านี้</button>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, inject } from "vue";
import { SET_REGISTER } from "@/store/modules/RegisterModel";
import { SET_TITLE } from "@/store/modules/Benefit";
import liff from "@line/liff";
export default defineComponent({
  name: "Profile",
  setup() {
    const store = inject("store");
    const router = inject("router");

    const Profire = store.getters.getProfire;
    const ProfireLine = store.getters.getProfireLine;
    const AppName = process.env.VUE_APP_NAME;

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });


    const gopage = (page) => {
      let data = [];

      switch (page) {
        case 'older':
          console.log('older');
          data.title = 'ผู้สูงอายุ';
          data.bg = '/older.png';


        
          store.dispatch(SET_TITLE, data);
          console.log(store.getters.getTitle);

    
          router.push('Older');
          break;
        case 'aids':
          data.title = 'ผู้ป่วยเอดส์';
          data.bg = '/aids.png';
          store.dispatch(SET_TITLE, data);
          router.push('Aids');
          break;
        case 'child':
          console.log('child');
          data.title = 'เด็กแรกเกิด';
          data.bg = '/child.png';
          store.dispatch(SET_TITLE, data);
          router.push('Child');
          break;
        case 'abnormal':
          console.log('abnormal');
          data.title = 'ผู้พิการ';
          data.bg = '/abnormal.png';
          store.dispatch(SET_TITLE, data);
          router.push('Abnormal');
          break;
        default:

      }







    };




    store.dispatch(SET_REGISTER, Profire);

    const close = () => {
      liff.ready.then(() => {
        liff.closeWindow();
      });
    };



    return {
      gopage,
      close,
      Profire,
      ProfireLine,
      AppName
    };
  },
});
</script>

<style scoped>
.card-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #01EAF9; */
  background-image: url('~@/assets/bg3.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;


  height: 250px;
  min-height: 250px;
  border-radius: 0px 0px 20px 20px !important;
  overflow: hidden;
  border-bottom: none !important;
  padding: 0 !important;
}

/* Create an overlay with the desired color and blending effect */
.card-header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #01EAF9;
  mix-blend-mode: screen;
}

.image-bg {
  opacity: 0.2;
  width: auto;
  height: 100%;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
}

.mini-h {
  font-size: 20px;
  margin-left: 5px;
}

.icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  color: white;
}

.header-logo-text {
  color: white;
  position: absolute;
  top: 42px;
  width: 100%;
}

.img-profile {
  width: 80px;
  border-radius: 50%;
  border: 2px solid rgb(255, 255, 255);
  background-color: white;
}

.title-home {
  font-size: 20px;
  font-weight: bold;
  background-color: #0196c280;
  color: whitesmoke;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.mn-profile {
  height: 50px;
  min-height: 50px;
  border-bottom: 2px solid var(--color);
  padding: 10px;
  background-color: white;
}

.card-header-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3EB6EB;
  text-align: center;
  height: 60px;
  min-height: 60px;
  position: absolute;
  bottom: 0;
  margin: 0;
  width: 100%;
  border-radius: 0px 0px 20px 20px;
}

.card-header-bottom h5 {
  font-size: 24px;
  color: white;
}

.card-header-bottom span {
  font-size: 22px;
  color: #FFF975 !important;
}

.bt-main {
  width: 100%;
  min-height: 60px;
  background-color: #0D3F94;
  color: white;
  border-radius: 10px;
  font-size: 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease-out;
}

.bt-main:hover {
  background-color: #0D3F94;
}

.bt-main:active {
  background-color: #1751bb;
  transition: background-color 0s;
  opacity: 0.5;
}
</style>
