export const SET_TITLE = "setTitle";
export default {
  state: {
    title: [],
  },
  getters: {
    getTitle(state) {
      return state.title;
    }
  },
  actions: {
    [SET_TITLE](state, payload) {
      state.commit(SET_TITLE, payload);
    }
  },
  mutations: {
    [SET_TITLE](state, payload) {
      state.title = payload;
    }
  },
};
