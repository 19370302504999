<template>
  <div class="card-header">

    <div class="title-box">
      <div style="
        width: 200px;
        height: 60px;
        position: absolute;
        right: -3px;
        bottom: 9px;
        text-align: right;
      ">
        <img src="@/assets/logo.png" style="width: 38%; border-radius: 50%" />
      </div>

      <div class="position-absolute text-end" style="right: 80px;top:8px">
        
        สวัสดิการที่ประชาชนได้รับ<br>
        จากกองสวัสดิการสังคมฝ่ายสังคมสงเคราะห์
      </div>

    </div>


    <!-- <div class="container pd-2 text-white">
      <img :src='data.bg' class="image-bg" style="background-color: #FFF874;" />
      <div>{{ AppName }}</div>
    </div> -->

    <div class="image-container">
      <img :src="data.bg" class="image-bg" alt="image" />

      <div class="position-absolute" style="font-size: 4rem;">{{ data.title }}</div>
      <div class="text-container">
        <p class="centered-text">{{ AppName }}</p>
      </div>
    </div>



  </div>
</template>

<script>
import { defineComponent, inject, ref } from "vue";
export default defineComponent({
  name: "Header",
  setup() {
    const store = inject("store");
    const AppName = process.env.VUE_APP_NAME;
    const data = ref(store.getters.getTitle);

    // onMounted(() => {
    //   data.value = store.getters.getTitle;
    // });


    return {
      AppName,
      data
    };
  },
});
</script>

<style scoped>


.title-box {
  position: relative;
  margin: 14px;
  border-radius: 30px 30px 30px 30px;
  height: 60px;
  background-color: white;
}

.title-box-logo {
  width: 100px;
  height: 160px;
  position: absolute;
  right: 0;
}

.image-bg {
  opacity: 0.3;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.card-header {
  position: relative;
  height: auto;
  min-height: 180px;
  overflow: hidden;
  border-bottom: none !important;
  padding: 0 !important;
  background-color: #153E88;
  color: #153E88;
}

.image-container {
  margin-left: 16px;
  margin-right: 16px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* Adjust display type based on your layout requirements */
  background-color: #FFF874;
}

.text-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* Add a background color to the text container */
  color: white;
  /* Set the text color */
}

.centered-text {
  color: #092256;
  margin: 0;
  /* Remove default margin */
  padding: 5px;
  /* Add some padding to the text */
}


/* .card-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('~@/assets/bg3.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;


  height: 250px;
  min-height: 250px;
  border-radius: 0px 0px 20px 20px !important;
  overflow: hidden;
  border-bottom: none !important;
  padding: 0 !important;
} */

/* Create an overlay with the desired color and blending effect */
/* .card-header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #01EAF9;
  mix-blend-mode: screen;
} */
</style>
