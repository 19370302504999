<template>
  <div class="card-fotter">



    <div class="bt-rainbow">
      <div class="bt-rainbow-box">
        ใบคำร้องขอลงทะเบียน >
      </div>
    </div>

    <div class="text-center text-white mt-4">

      จัดทำโดย <br>

      กองสวัสดิการสังคม เทศบาลตำบลเทพราช<br>
      ติดต่อสอบถามได้ที่ โทร.038-595633 ต่อ 106<br>
      www.tepparad.go.th

    </div>




  </div>
</template>

<script>
import { defineComponent, inject, ref } from "vue";
export default defineComponent({
  name: "Header",
  setup() {
    const store = inject("store");
    const AppName = process.env.VUE_APP_NAME;
    const data = ref(store.getters.getTitle);

    // onMounted(() => {
    //   data.value = store.getters.getTitle;
    // });


    return {
      AppName,
      data
    };
  },
});
</script>

<style scoped>
.card-fotter {
  padding: 16px;

  background-color: transparent;
}

.bt-rainbow {
  display: flex;
  padding: 5px;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  min-height: 80px;
  background: rgb(105, 207, 249);
  background: linear-gradient(90deg, rgba(105, 207, 249, 1) 16%, rgba(163, 157, 254, 1) 40%, rgba(237, 149, 238, 1) 90%);
  color: white;
  border-radius: 40px;
  font-size: 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease-out, opacity 0.3s ease-out;
  /* Added opacity transition */
}

.bt-rainbow:hover {
  background-color: #0D3F94;
  opacity: 0.8;
  animation: pulse 1s infinite;
  /* Added pulse animation on hover */
}

.bt-rainbow:active {
  background-color: #1751bb;
  transition: background-color 0s;
  /* Removed the opacity transition from active state */
  animation: bounce 0.3s;
  /* Added bounce animation on click */
}

/* Pulse Animation */
@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

/* Bounce Animation */
@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5px);
  }

  100% {
    transform: translateY(0);
  }
}

.bt-rainbow-box {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid white;
  border-radius: 35px;
  height: 70px;
  width: 100%;
}
</style>
