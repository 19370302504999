<template>
  <div class="vh100 pb-4" style="background-color: #153e88; overflow-y: scroll">
    <Header> </Header>
    <div class="card-body">
      <div class="container pt-3">
        <div class="text-start">
          สิทธิประโยชน์สำหรับผู้พิการ<br />
          &nbsp; &nbsp; &nbsp; &nbsp; พระราชบัญญัติส่งเสริมและพัฒนาคุณภาพชีวิต
          คนพิการ พ.ศ.๒๕๕๐ เป็นกฎหมายที่ให้การฟื้นฟูสมรรถภาพ คนพิการ
          การส่งเสริมและการพัฒนาคุณภาพชีวิตคนพิการ
          และสิทธิประโยชน์ที่ผู้พิการพึงได้รับ เช่น การบริการฟื้นฟู
          สมรรถภาพและค่าใช้จ่ายในการรักษาพยาบาล การรับเงิน
          สงเคราะห์เบี้ยความพิการ
        </div>
        <div class="line mt-3 pb-3"></div>
        <div class="text-start mt-2">
          คุณสมบัติของผู้มีสิทธิได้รับเงินเบี้ยความพิการ<br />
        </div>
        <div class="text-start ms-2">
          &nbsp;&nbsp;&nbsp;&nbsp;๑. มีสัญชาติไทย <br />
          &nbsp;&nbsp;&nbsp;&nbsp;๒. มีภูมิลำเนาอยู่ในเขตเทศบาลตำบลเทพราชตาม
          ทะเบียนบ้าน
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;๓. มีบัตรประจำตัวคนพิการตามกฎหมายว่าด้วย
          การส่งเสริมคุณภาพชีวิตคนพิการ <br />
          &nbsp;&nbsp;&nbsp;&nbsp;๔. ไม่เป็นบุคคลซึ่งอยู่ในความอุปการะของสถาน
          สงเคราะห์ของรัฐ<br />
        </div>

        <div
          class="d-flex justify-content-center align-items-center mt-4"
          style="
            border-radius: 30px;
            color: white;
            background-color: #153e88;
            width: 100%;
            height: 50px;
            text-align: center;
            font-size: 16px;
          "
        >
          ขั้นตอนการยื่นคำขอ
        </div>

        <div class="text-start ms-2 mt-2">
          &nbsp;&nbsp;&nbsp;&nbsp;สำหรับผู้พิการที่ประสงค์จะลงทะเบียนขอรับเบี้ยความ
          พิการ มายื่นคำขอรับเงินเบี้ยความพิการได้ทุกวันทำการ
          (ยกเว้นวันหยุดราชการ) ด้วยตนเองต่อเทศบาลตำบลเทพ ราช
          หรือสถานที่ที่เทศบาลตำบลเทพราชกำหนด โดยมีหลัก ฐาน ดังนี้
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ๑.
          บัตรประจำตัวคนพิการตามกฎหมายว่าด้วยการ ส่งเสริมคุณภาพชีวิตคนพิการ
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;๒. ทะเบียนบ้าน<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;๓. สมุดบัญชีเงินฝากธนาคาร
          <br />
        </div>
      </div>

      <div
        class="container position-relative mt-3"
        style="background-color: #fff975; width: 100%"
      >
        <div class="text-center pt-3" style="font-size: 16px">
          งานบริการของเทศบาลตำบลเทพราช สำหรับผู้พิการ
        </div>

        <div class="text-start ms-2 p-3">
          เทศบาลตำบลเทพราช อำนวยความสะดวกสำหรับ
          ผู้พิการในการนำส่งเอกสารต่อเพื่อจัดทำ/ต่อบัตรประจำ ตัวคนพิการ ณ
          สำนักงานพัฒนาสังคมและความมั่นคง ของมนุษย์ จังหวัดฉะเชิงเทรา
          โดยผู้พิการหรือผู้ดูแลจะ ต้องนำเอกสารมายื่น ณ เทศบาลตำบลเทพราช ดังนี้
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;๑.
          สำเนาบัตรประจำตัวประชาชนของผู้พิการ ๑ ฉบับ<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;๒.
          สำเนาทะเบียนบ้านของผู้พิการ ๑ ฉบับ<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;๓. รูปถ่ายคนพิการ ขนาด ๑
          นิ้ว จำนวน ๒ รูป<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;๔.
          ใบรับรองแพทย์ซึ่งออกโดยผู้ประกอบวิชาชีพเวชกรรมของสถานพยาบาลขแงรัฐหรือเอกชนที่เลขาธิการ
          สนง.ส่งเสริมและพัฒนาคุณภาพชีวิตคนพิการแห่งชาติกำหนด แก้ไขเป็น
          ใบรับรองแพทย์ซึ่งออกโดยสถานพยาบาลของรัฐ
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;๕. กรณีที่มีผู้ดูแลคนพิการ
          ให้แนบสำเนาบัตร ประชาชนหรือสำเนาทะเบียนบ้านของผู้ดูแลคนพิการ จำนวน ๑
          ฉบับ<br />
        </div>
      </div>

      <div class="container pb-4">
        <div class="text-start ms-2 pt-3">
          เบี้ยยังชีพผู้พิการที่ได้รับ <br />
        </div>
        <div class="text-center">
          อายุ ๖๐-๖๙ ปี ได้รับเดือนละ ๖๐๐ บาท <br />
        </div>
      </div>
    </div>

    <Fotter></Fotter>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Fotter from "@/components/Fotter";
import { defineComponent, onMounted, inject } from "vue";
import { SET_REGISTER } from "@/store/modules/RegisterModel";
import liff from "@line/liff";
export default defineComponent({
  name: "Older",
  components: {
    Header,
    Fotter,
  },
  setup() {
    const store = inject("store");
    const router = inject("router");

    const Profire = store.getters.getProfire;
    const ProfireLine = store.getters.getProfireLine;
    const AppName = process.env.VUE_APP_NAME;

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const close = () => {
      liff.ready.then(() => {
        liff.closeWindow();
      });
    };

    const edit = () => {
      store.dispatch(SET_REGISTER, Profire);
      router.push({ name: "Edit" });
    };

    return {
      edit,
      close,
      Profire,
      ProfireLine,
      AppName,
      Header,
    };
  },
});
</script>

<style scoped>
.line {
  margin-top: 5px;
  margin-bottom: 5px;
  border-top: 2px solid yellow;
}

.mini-h {
  font-size: 20px;
  margin-left: 5px;
}

.icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  color: white;
}

.header-logo-text {
  color: white;
  position: absolute;
  top: 42px;
  width: 100%;
}

.img-profile {
  width: 80px;
  border-radius: 50%;
  border: 2px solid rgb(255, 255, 255);
  background-color: white;
}

.title-home {
  font-size: 20px;
  font-weight: bold;
  background-color: #0196c280;
  color: whitesmoke;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.mn-profile {
  height: 50px;
  min-height: 50px;
  border-bottom: 2px solid var(--color);
  padding: 10px;
  background-color: white;
}

.card-header-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3eb6eb;
  text-align: center;
  height: 60px;
  min-height: 60px;
  position: absolute;
  bottom: 0;
  margin: 0;
  width: 100%;
  border-radius: 0px 0px 20px 20px;
}

.card-header-bottom h5 {
  font-size: 24px;
  color: white;
}

.card-header-bottom span {
  font-size: 22px;
  color: #fff975 !important;
}

.bt-main {
  width: 100%;
  min-height: 60px;
  background-color: #0d3f94;
  color: white;
  border-radius: 10px;
  font-size: 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease-out;
}

.bt-main:hover {
  background-color: #0d3f94;
}

.bt-main:active {
  background-color: #1751bb;
  transition: background-color 0s;
  opacity: 0.5;
}

.card-body {
  padding: 0;
  margin-top: 0;
  margin-left: 16px;
  margin-right: 16px;
  background-color: white;
}

.card-box {
  padding: 16px;
  margin-left: 16px;
  margin-right: 16px;
  background-color: transparent;
  color: #fff975;
  border-radius: 15px;
  border: 2px solid rgb(252, 252, 252);
}
</style>
